@import url(../src/vendor/normalize.css);
@import url(../src/vendor/fonts/fonts.css);

html {
  scroll-behavior: smooth;
}

#root {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
  color: black;
}

.app {
  position: relative;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.header {
  box-sizing: border-box;
  position: relative;
  padding: 48px 52px;
  max-height: 640px;
  width: 100%;
  background-image: url(../src/images/me_cropped_tmp.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.header__title {
  margin: 0 0 0 -5px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 76px;
  line-height: 76px;
}

.header__subtitle {
  margin: 8px 0 0;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  opacity: 0.8;
}

.header__nav-icons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.header__nav-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  mix-blend-mode: normal;
  transition: mix-blend-mode 1s ease-in-out;
}

.header__nav-icon-linkedin {
  background-image: url(./images/svg/li.svg);
  transition: background-image 0.3s ease-in-out;
}

.header__nav-icon-git {
  transition: background-image 0.3s ease-in-out;
  background-image: url(./images/svg/git.svg);
}

.header__nav-icon-instagram {
  background-image: url(./images/svg/insta.svg);
  transition: background-image 0.3s ease-in-out;
}

.nav-items {
  display: flex;
  flex-direction: column;
  margin: 80px 0 0;
  padding: 0;
  list-style: none;
}

.nav__item {
  box-sizing: border-box;
  display: block;
  background-color: transparent;
  max-width: fit-content;
  border: none;
  margin: 0 0 0 -8px;
  padding: 6px 8px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  cursor: pointer;
  white-space: nowrap;
}

.header__contacts {
  position: relative;
  margin: 100px 0 0 0;
  /* bottom: 0; */
}

.header__email {
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  margin: 8px 0 8px -8px;
  padding: 8px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  opacity: 0.8;
}

.main {
  padding: 60px 52px;
}

.main__about {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.main__skills {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
}

.main__section-title {
  margin: 60px 0 20px 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 37px;
  text-transform: uppercase;
}

.main__project {
  width: 100%;
  box-shadow: 0px 0 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.main__project-infostash {
  position: relative;
  height: 0;
  background-image: url(../src/images/infostash.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 75%;
  overflow: hidden;
}

.main__project-infostash-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 32px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.main__project-infostash-info-title {
  margin: 0 0 12px 0;
  padding: 0;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 24px;
  font-variant: small-caps;
  display: none;
}

.main__project-infostash-info-text {
  margin: 0;
  padding: 0;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 24px;
  display: none;
}

.main__project-infostash-description {
  max-width: 55%;
  margin-bottom: 10%;
}

.main__project-infostash-description-header__title {
  padding: 0;
  margin: 0 0 24px 0;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 64px;
  color: white;
  text-align: center;
  font-variant: small-caps;
}

.main__project-infostash-description-header__content {
  padding: 0;
  margin: 8px 0 0 0;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  color: white;
  text-align: justify;
}

.bold {
  margin-top: 35px;
  font-weight: 600;
}

.main__ux {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.main__ux-project {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 8px;
  box-shadow: 0px 0 16px rgba(0, 0, 0, 0.15);
}

.main__ux-project-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  padding: 32px;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  border-radius: 8px;
}

.main__ux-project-title {
  margin: 0;
  padding: 0;
  color: white;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 46px;
  text-align: center;
  font-variant: small-caps;
}

.main__ux-project-about {
  padding: 0;
  margin: 10px 0 0 0;
  color: white;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.photomemo {
  background-image: url(./images/ux/photomemo.jpg);
}

.bookdrop {
  background-image: url(./images/ux/bookdrop.jpg);
}

.jazz {
  background-image: url(./images/ux/jazz.jpg);
}

.ecommerce {
  background-image: url(./images/ux/ffordes.jpg);
}

@media (hover: hover) {
  .header__nav-icon-linkedin:hover {
    background-image: url(./images/svg/li\ -\ Copy.svg);
  }

  .header__nav-icon-git:hover {
    background-image: url(./images/svg/git\ -\ Copy.svg);
  }

  .header__nav-icon-instagram:hover {
    background-image: url(./images/svg/insta\ -\ Copy.svg);
  }
  .header__nav-icon:hover {
    box-shadow: 0px 0 4px rgba(0, 0, 0, 0.25);
  }
  .nav__item:hover {
    background-color: black;
    color: white;
  }

  .header__email:hover {
    background-color: black;
    color: white;
  }

  .main__project-infostash-overlay:hover {
    opacity: 1;
  }

  .main__ux-project-overlay:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 950px) {
  .header {
    padding: 32px 32px;
    background-image: url(../src/images/me_cropped_tmp_tablet.jpg);
  }
}

@media screen and (max-width: 650px) {
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    width: 100%;
    background-image: url(../src/images/me_cropped_tmp_mobile.jpg);
    background-size: 100%;
  }

  .header__title {
    margin: 30px 0 0 20px;
    align-self: flex-start;
    max-width: 50%;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
  }

  .header__subtitle {
    /* margin: 0; */
    margin-left: 20px;
    align-self: flex-start;
    max-width: 40%;
    font-size: 18px;
    line-height: 26px;
    font-variant: normal;
    font-weight: 500;
  }

  .header__contacts {
    margin: 160px 0 0;
    padding-bottom: 14px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav {
    align-self: flex-start;
  }

  .nav-items {
    background-color: #f5f5f5;
    margin: 45px 0 0;
  }

  .nav__item {
    padding-left: 0;
    padding-right: 20px;
    margin-left: 20px;
    background-color: #f5f5f5;
  }

  .main {
    padding: 20px;
  }

  .main__project {
    margin-bottom: 10px;
  }

  .main__project-infostash-info-title {
    text-align: center;
    display: block;
  }
  .main__project-infostash-info-text {
    display: block;
  }
  .main__section-title {
    text-align: center;

    margin: 40px 0 20px 0;
  }
  .main__ux {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .main__ux-project-overlay {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.24);
    border-radius: 8px;
  }
  .main__ux-project-title {
    color: black;
    background-color: hsla(0, 0%, 100%, 0.95);
    padding: 10px;
    font-variant: normal;
    font-size: 24px;
    text-transform: uppercase;
    border-radius: 16px;
  }
  .main__ux-project-about {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header__contacts {
    margin: 100px 0 0;
  }
}
