/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url(./montserrat-v23-latin-regular.woff),
    url(./montserrat-v23-latin-regular.woff2);
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url(./montserrat-v23-latin-500.woff),
    url(./montserrat-v23-latin-500.woff2);
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url(./montserrat-v23-latin-600.woff),
    url(./montserrat-v23-latin-600.woff2);
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url(./montserrat-v23-latin-700.woff),
    url(./montserrat-v23-latin-700.woff2);
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url(./montserrat-v23-latin-800.woff),
    url(./montserrat-v23-latin-800.woff2);
}
